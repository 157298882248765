import MainLayout from '@/components/main-layout';
import ProjectsGallery from '@/components/projects/projects-gallery';
import SEO from '@/components/seo';
import { graphql } from 'gatsby';
import React from 'react';

type OstatneStavbyProps = {
  data: any;
};

const OstatneStavby: React.FC<OstatneStavbyProps> = ({ data }) => {
  const {
    allContentfulProjects: { nodes: projects },
  } = data;
  return (
    <>
      <SEO
        title="Projekty - Ostatné stavby"
        description="Pozrite si ukážku našich projektov z kategórie Ostatných stavieb."
        image="/other.jpg"
      />
      <MainLayout>
        <ProjectsGallery projects={projects} />
      </MainLayout>
    </>
  );
};

export const query = graphql`
  {
    allContentfulProjects(filter: { category: { eq: "other" } }) {
      nodes {
        projectName
        photos {
          thumb: gatsbyImageData(width: 450, height: 220, placeholder: BLURRED)
          full: gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

export default OstatneStavby;
